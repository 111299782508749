import React, { useState } from "react";
import TrendingFlatRoundedIcon from "@mui/icons-material/TrendingFlatRounded";

function FeatureCard({ card, i }: any) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    // <div
    //   className="flex flex-col justify-between p-5 rounded-md bg-[#6C19F30D] h-[300px] max-w-[250px] border hover:border-[#684FFF] hover:shadow-lg"
    //   onMouseEnter={() => setIsHovered(true)}
    //   onMouseLeave={() => setIsHovered(false)}
    // >
    //   <div></div>
    //   <div className="flex flex-col gap-2">
    //     <p className="text-[28px] leading-8 text-[#0A1931]">{card.text}</p>
    //     <div></div>
    //     <div className="flex gap-2 cursor-pointer flex-col">
    //       <span className="bg-[#D2CBFF] w-12 h-[2px] rounded-xl"/>
    //       <span className={`text-[#684FFF] text-[16px] font-semibold ${isHovered ? "" : "hidden"}`}>KNOW MORE</span>
    //       <TrendingFlatRoundedIcon
    //         className="text-black"
    //         sx={{ color: isHovered ? "#684FFF" : "black" }}
    //       />
    //     </div>
    //   </div>
    // </div>

    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front flex flex-col items-start justify-between">
          {/* <img src="img_avatar.png" alt="Avatar" style="width:300px;height:300px;"> */}
          <div>
            <img src={card.icon} className="w-20" alt="" />
          </div>
          <div>
            <div className="text-start text-[16px] font-medium">
              {card.text}
            </div>
            <div className="text-start text-[20px] text-[#D0C4FB] font-medium">
              0{i + 1}
            </div>
          </div>
        </div>
        <div className="flip-card-back flex justify-center items-center p-10">
          <p className="text-[14px]">{card.secText} </p>
        </div>
      </div>
    </div>
  );
}

export default FeatureCard;
