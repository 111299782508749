import { title } from "process";
import React from "react";
import {
  MailOutline,
  LocationOnOutlined,
  CallOutlined,
} from "@mui/icons-material";
import FaceBookIcon from "../../assets/icons/facebook.svg";
import TwitterIcon from "../../assets/icons/twitter.svg";
import InstagramIcon from "../../assets/icons/instagram.svg";

const address = [
  {
    title: "Address",
    text: "City light liberty Bellandur Bangalore 560103",
    icon: LocationOnOutlined,
  },
  {
    title: "Email",
    text: "Team_kistify@kistify.com",
    icon: MailOutline,
  },
  {
    title: "Phone",
    text: "6201225752",
    icon: CallOutlined,
  },
];

const socialMedia = [
  {
    icon: FaceBookIcon,
    url: "",
  },
  {
    icon: TwitterIcon,
    url: "",
  },
  {
    icon: InstagramIcon,
    url: "",
  },
];

function Contact() {
  return (
    <div
      id="Contact"
      className="flex flex-col md:flex-row justify-center items-center w-full bg-[#F6F6FF] p-10 px-36  gap-5 h-[auto]"
    >
      <div className="flex flex-col gap-8 w-full md:w-1/2 p-8 bg-white">
        <h1 className="text-[28px] font-medium">Let me know here</h1>
        <div className="flex gap-3">
          <input
            type="text"
            placeholder="Full Name"
            className="w-full p-3 border border-[#DFE3EB]"
          />
          <input
            type="text"
            placeholder="Email address"
            className="w-full p-3 border border-[#DFE3EB]"
          />
        </div>
        <input
          type="text"
          placeholder="Subjects"
          className="p-3 w-full border border-[#DFE3EB]"
        />
        <textarea
          rows={5}
          placeholder="Message"
          className="p-3 border border-[#DFE3EB]"
        />
      </div>
      <div className="flex flex-col w-full md:w-1/2 bg-white h-full p-10 gap-6">
        <h1 className="text-[28px] font-medium"> Get In Touch</h1>
        <div className="flex flex-col gap-5 ">
          {address.map((add, index) => (
            <div className="flex gap-5 items-center">
              <div className="bg-[#684FFF1A] p-2 rounded-full w-auto">
                <add.icon className="text-[#684FFF]" />
              </div>
              <span className="text-[14px] break-all">{add.text}</span>
            </div>
          ))}
        </div>
        <div className="flex gap-4">
          {socialMedia.map((social, index) => (
            <a
              className="p-3 rounded-full cursor-pointer border border-[#DFE3EB] hover:scale-110"
              href={social.url}
              target="_blank"
            >
              <img src={social.icon} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Contact;
