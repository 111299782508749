import React from 'react'

function Footer() {
  return (
    <div className='text-[14px] p-5 flex justify-center'>
      © 2023 - design & develop by <span className='text-[#7454F2] ml-[6px]'>Kistify</span>
    </div>
  )
}

export default Footer