import React from "react";

function MemberCrad({ member }: any) {
  return (
    <div className="flex items-center text-[12px] gap-4 px-10 py-5 cursor-pointer scrollba hover:bg-[#F1E8FE]">
      <div>
        <img
          src={member.profilePic}
          alt="profile_pic"
          className="w-14 h-14 grayscale  rounded-full"
        />
      </div>
      <div>
        <p className="text-[14px]">{member.name}</p>
        <p className="text-[#737D8C]">
          {member.role} <span className="text-[#7454F2]">Kistify</span>
        </p>
        <p className="text-[#737D8C]">{member.companies}</p>
      </div>
    </div>
  );
}

export default MemberCrad;
