import React from "react";
import MemberCrad from "../MemberCrad/MemberCrad";
import Dots from "../../assets/images/Dot.svg";
import SouravImg from "../../assets/images/SouravImg.svg";
import IshanImg from "../../assets/images/ishan.svg";
import Aryan from "../../assets/images/aryan.svg";
import Amit from "../../assets/images/amit.svg";
import Vedika from "../../assets/images/vedika.svg";
import Vishal from "../../assets/images/vishal.jpg";
const memberData = [
  {
    name: "Sourav Kumar",
    role: "Co-founder",
    companies: "Razorpay, Revolut",
    profilePic: SouravImg,
  },
  {
    name: "Ishan Raj",
    role: "Co-founder",
    companies: "Arista Networks",
    profilePic: IshanImg,
  },
  {
    name: "Aryan Nair",
    role: "Founding Tech",
    companies: "Flipkart",
    profilePic: Aryan,
  },
  {
    name: "Amit Singh",
    role: "Founding Product",
    companies: "Sprinklr",
    profilePic: Amit,
  },
  {
    name: "Vedika Singhania",
    role: "Founding Designer",
    companies: "K12, Trustt, Novopay",
    profilePic: Vedika,
  },
  {
    name: "Vishal Magdum",
    role: "Founding Frontend",
    companies: "AptaChain, Trustt",
    profilePic: Vishal,
  },
];

function About() {
  return (
    <div id="Team">
      <div className="flex flex-col md:flex-row justify-center items-center px-20 py-10 gap-[80px]">
        <div className="w-[280px] flex flex-row md:flex-col md:h-[400px] overflow-auto">
          {memberData.map((member, index) => (
            <MemberCrad member={member} />
          ))}
        </div>
        <div className="relative max-w-[600px] bg-[#0A1931] pb-16 pt-5 pl-32 pr-10 flex flex-col justify-start items-start ">
          <div className="text-white text-[26px] w-full text-center my-5">
            Meet the Team
          </div>
          <div className="text-white text-[12px] pb-5 text-justify">
            Our team is a dynamic and passionate group of professionals with
            extensive expertise across fintech, e-commerce, and SaaS sectors.
            With a proven track record in product management and technology
            development, we bring a unique synergy to our projects. What truly
            sets us apart is our strong bond: we've been collaborating and
            growing together for over six years, creating a solid foundation of
            trust and shared knowledge.
          </div>
          <div className="absolute top-[20%] left-6 z-10">
            <img src={Dots} alt="Image" className=" " />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
