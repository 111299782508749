import React from "react";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Calculator from "./components/Calculator/Calculator";
import Feature from "./components/Feature/Feature";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Rocket from "./assets/icons/rocket.png";

function App() {
  return (
    <div className="App">
      <div className="flex z-20 p-1 justify-center items-center gap-3 font-normal bg-[#7454F2] text-white">
        <div className="text-[12px]">
          join the waitlist and get instant quick loan at least interest rate
        </div>
        <img height="20px" width="20px" src={Rocket} alt="rocket" />
      </div>
      <Header />
      <Home />
      <Calculator />
      <Feature />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
