import React, { useEffect, useState } from "react";
import AppleLogo from "../../assets/icons/AppleLogo.svg";
import CheckMark from "../../assets/icons/CheckMark.svg";
import PhoneHand_1 from "../../assets/images/phone_hand1.png";
import PhoneHand_2 from "../../assets/images/phone_hand2.png";
import PhoneHand_3 from "../../assets/images/phone_hand3.png";
import CAMSLogo from "../../assets/images/C.A.M.S._logo.svg.png";
import KFintechLogo from "../../assets/images/kfintech-white-tagline.svg";
import DGLockerLogo from "../../assets/images/digilocker_logo_new.png";
import CERSAILogo from "../../assets/images/CERSAI_Logo.jpg";
import NSDLLogo from "../../assets/images/nsdl-logo.png";

const fearuresList = [
  "No Credit check",
  "Lowest interests rate",
  "Best Loan to value",
  "Instant Disbursal",
];

const brands = [CAMSLogo, KFintechLogo, NSDLLogo, DGLockerLogo, CERSAILogo];

function Home() {
  const [imageSrc, setImageSrc] = useState(PhoneHand_1); // Initial image source
  const [animatedText, setAnimatedText] = useState("Mutual Fund");

  useEffect(() => {
    // Define an array of image paths
    const imagePaths = [PhoneHand_1, PhoneHand_2];
    const animatedTexts = ["Mutual Fund", "Insurance", "FD’s"];

    // Set an interval to change the image every 3 seconds
    const interval = setInterval(() => {
      // Calculate the index of the next image
      const currentIndex = imagePaths.indexOf(imageSrc);
      const nextIndex = (currentIndex + 1) % imagePaths.length;

      // Update the image source
      setImageSrc(imagePaths[nextIndex]);
    }, 2000);

    const textInterval = setInterval(() => {
      // Calculate the index of the next image
      const currentIndex = animatedTexts.indexOf(animatedText);
      const nextIndex = (currentIndex + 1) % animatedTexts.length;

      // Update the image source
      setAnimatedText(animatedTexts[nextIndex]);
    }, 2000);

    // Clear the interval on component unmount
    return () => {
      clearInterval(interval);
      clearInterval(textInterval);
    };
  }, [imageSrc, animatedText]); // Run effect whenever imageSrc changes

  return (
    // <div className="text-3xl flex overflow-clip">
    //   <div className="w-auto flex-1 pt-[5%] pl-[8%]">
    //     <div>
    //       <div className="text-[40px] lg:text-[60px]  font-normal leading-snug">
    //         Instant Loan against <br></br> your{" "}
    //         <span className="font-semibold text-[#7454F2]">
    //           {animatedText}{" "}
    //         </span>
    //       </div>
    //     </div>
    //     <div className="text-[#7454F2] mt-2 text-[18px] font-medium">
    //       Earn<span className="text-[#7F7F7F]"> While you</span> borrow !
    //     </div>
    //     <button className="uppercase mt-2 bg-[#7454F2] text-[12px] rounded px-3 py-1 text-[white] flex gap-4">
    //       <img src={AppleLogo} alt="" />
    //       Download Now!
    //     </button>
    //     <div className="mt-6">
    //       {fearuresList.map((feature) => (
    //         <div className="flex gap-3 text-[18px] text-[#4B5563]">
    //           <img src={CheckMark} alt="check" /> <span>{feature}</span>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    //   <div className="bg-[#E9E5FF] w-[30%] relative hidden md:block">
    //     <img
    //       src={imageSrc}
    //       alt="Image"
    //       className="absolute bottom-[55px] "
    //       style={{ left: "50%", transform: "translateX(-65%)", scale: "1.4" }}
    //     />
    //     {/* <div className="absolut bottom-0">
    //       <img src={PhoneHand_3} alt="" />
    //     </div> */}
    //   </div>
    // </div>

    <div id="About" className="relative text-3xl flex overflow-clip">
      <div className="w-auto flex gap-10 flex-col justify-between flex-1 pt-[5%] pl-[8%] pb-[100px]">
        <div className="flex-1">
          <div>
            <div className="text-[40px] lg:text-[48px] font-normal leading-tight">
              Instant Loan against <br /> your{" "}
              <span className="font-semibold text-[#7454F2]">
                {animatedText}{" "}
              </span>
            </div>
          </div>
          <div className="text-[#7454F2] mt-2 text-[14px] font-medium">
            Earn<span className="text-[#7F7F7F]"> While you</span> borrow!
          </div>
          <button className=" mt-2 bg-[#7454F2] text-[16px] font-semibold rounded-lg px-4 py-2 text-[white]">
            Download Now!
          </button>
          <div className="mt-6 flex flex-col gap-0">
            {fearuresList.map((feature, index) => (
              <div
                key={index}
                className="flex items-center -mt-1 gap-3 text-[14px] text-[#4B5563]"
              >
                <img src={CheckMark} className="w-4 h-4" alt="check" />{" "}
                <span>{feature}</span>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="p-2 flex flex-col gap-2">
          <div className="w-full text-center text-[#C9C8CE] text-[18px]">
            OUR PARTNERS
          </div>
          <div className="flex justify-center items-center gap-4">
            {brands.map((brand, index) => (
              <div className="flex justify-center items-center rounded-xl bg-contain w-28 p-3 bg-[#F9FAFB]">
                <img src={brand} className=" w-auto h-10 grayscale" alt="" />
              </div>
            ))}
          </div>
        </div> */}
      </div>
      <div className="bg-[#E9E5FF] w-[30%] hidden md:block">
        <div className="absolute bottom-0  inset-0 flex left-[52%]">
          <img src={imageSrc} alt="Image" className=" " />
        </div>
      </div>
    </div>
  );
}

export default Home;
