import React, { useState } from "react";
import Logo from "../../assets/icons/kistifyLogo.svg";

const Navigations = ["About", "Calculator", "Team", "Contact"];

function Header() {
  const [activeNav, setActiveNav] = useState(""); // State to track active navigation item

  const handleNavClick = (navItem: string) => {
    setActiveNav(navItem); // Update active navigation item on click
  };

  return (
    <div className="sticky top-0 w-full z-50 bg-white">
      <div className="flex justify-between  px-8 py-2">
        <div className="flex items-center gap-1">
          <img src={Logo} height="30px" width="30px" alt="" />
          <span className="text-[30px] text-[#7454F2]">istify</span>
        </div>
        <nav className="hidden text-sm md:flex flex-grow justify-center items-center gap-6">
          {Navigations.map((nav, index) => (
            <a
              href={"#" + nav}
              className={`nav-link text-[#7F7F7F] hover:text-[#7454F2] text-[16px] ${
                activeNav === nav ? "text-[black] font-bold" : ""
              }`}
              onClick={() => handleNavClick(nav)}
            >
              {nav}
            </a>
          ))}
        </nav>
        <button className=" bg-[#7454F2] text-[16px] font-semibold rounded-lg px-4 py-2 text-[white]">
          Check Eligibility
        </button>
      </div>
    </div>
  );
}

export default Header;
