import React from "react";
import ArrowRight from "../../assets/icons/ArrowRight.svg";
import TrendingFlatRoundedIcon from "@mui/icons-material/TrendingFlatRounded";
import illustration from "../../assets/images/illustration1.svg";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import DotIcon from "../../assets/images/Dot.png";
function Calculator() {
  return (
    // <div className="flex flex-col md:flex-row w-full items-center  calulaterImg px-5 py-10">
    //   <div className="w-full p-10  flex flex-col justify-center items-center">

    //     <img src={illustration} />
    //   </div>
    //   <div className="">
    //     <div className="text-[30px] font-normal">
    //       Let you investment <span className="text-[#7454F2]"> Grow</span>
    //     </div>
    //     <p className="text-[#737D8C] font-normal text-[16px] mt-5">
    //       Let your investment grow with thw market and fill your instant cash
    //       need with us at lower interest rate then on your investment our
    //       interest rate are as low as 9.5%. your bad CIBIl or no credit history
    //       wont bother us
    //     </p>
    //     <div className="flex gap-5 items-center mt-8">
    //       <div className="flex items-center gap-3">
    //         <span className="text-[#684FFF] text-[40px] font-normal">
    //           9.5 %
    //         </span>
    //         <span>
    //           Lowest <br></br> interest rate
    //         </span>
    //       </div>
    //       <div className="flex items-center gap-3">
    //         <span className="text-[#684FFF] text-[40px] font-normal">NO</span>
    //         <span>
    //           CIBIL <br></br> required
    //         </span>
    //       </div>
    //     </div>

    //     <div className="mt-8">
    //       <button className="primary-btn flex gap-1">
    //         check eligibility{" "}
    //         <TrendingFlatRoundedIcon sx={{ color: "white" }} />
    //       </button>
    //     </div>
    //   </div>
    // </div>

    <div
      id="Calculator"
      className="relative  flex justify-center items-center overflow-clip"
    >
      <div className="w-[400px] h-[400px] -ml-[200px] -mt-[200px] bg-[#EEEEFF] rounded-full absolute top-0 left-0 flex justify-end items-center">
        <img
          src={DotIcon}
          className="absolute bottom-[20%] scale-[0.6] right-[30%]"
        />
      </div>
      <div className="relative z-10 py-[80px] md:px-[80px] flex flex-col justify-center items-center md:flex-row gap-10 top-2">
        <div className="w-1/2 h-full">
          <img src={illustration} alt="Placeholder" />
        </div>
        <div className="w-1/2 flex flex-col justify-between">
          <div className="text-[30px] font-normal">
            Let you investment <span className="text-[#7454F2]"> Grow</span>
          </div>
          <p className="text-[#737D8C] font-normal text-[16px] mt-5">
            Let your investment grow with thw market and fill your instant cash
            need with us at lower interest rate then on your investment our
            interest rate are as low as 9.5%. your bad CIBIl or no credit
            history wont bother us
          </p>
          <div className="flex flex-col sm:flex-row gap-5 justify-start  mt-8 h-full">
            <div className="flex items-center justify-start gap-3">
              <span className="text-[#684FFF] text-[40px] font-normal">
                9.5 %
              </span>
              <span>
                Lowest <br></br> interest rate
              </span>
            </div>
            <div className="flex items-center justify-start gap-3">
              <span className="text-[#684FFF] text-[40px] font-normal">NO</span>
              <span>
                CIBIL <br></br> required
              </span>
            </div>
          </div>
          <div className="mt-8">
            <button className="primary-btn flex items-center gap-1">
              check eligibility
              <TrendingFlatRoundedIcon sx={{ color: "white" }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calculator;
