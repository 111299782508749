import React from "react";
import FeatureCard from "./FeatureCard";
import illustrationCard1 from "../../assets/images/illustrationCard1.svg";
import illustrationCard2 from "../../assets/images/illustrationCard2.svg";
import illustrationCard3 from "../../assets/images/illustrationCard3.svg";
import illustrationCard4 from "../../assets/images/illustrationCard4.svg";
const fearuresList = [
  {
    text: "Declare your investment",
    icon: illustrationCard1,
    url: "",
    secText:
      "Declare Your Investments, Watch Them Grow, and Gain Valuable Insights with Our Portfolio Rebalancing Recommendations!",
  },
  {
    text: "Get recommended",
    icon: illustrationCard2,
    url: "",
    secText:
      "Instant Cash Needs? No Worries. Let Kistify's Recommendation Engine Guide You to Optimal Assets and Lenders for the Best Loans!",
  },
  {
    text: "Pledge your investment",
    icon: illustrationCard3,
    url: "",
    secText:
      "Pledge Your Investments with a Trusted Government Entity and Witness Their Growth at Market Rates!",
  },
  {
    text: "Instant Disbursal",
    icon: illustrationCard4,
    url: "",
    secText:
      "Instant Loan Needs? Our Lending Partner Can Disburse Funds in as Fast as 30 Minutes!",
  },
];

function Feature() {
  return (
    <div id="" className="p-6">
      <div className="flex flex-col items-center">
        <span className="text-[40px]">Unique Feature</span>
        <div className="h-[3px] w-20 bg-[#7454F2] mt-2 rounded-lg"></div>
      </div>
      <div className="flex justify-center ">
        <div className="grid items-center justify-center grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-10">
          {fearuresList.map((feature, index) => (
            <FeatureCard card={feature} i={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Feature;
